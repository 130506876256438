<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">运营工具</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">文件盖章</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 15px">
            <div title="文件名称" class="searchboxItem ci-full">
              <span class="itemLabel">文件名称:</span>
              <el-input
                v-model="fileName"
                clearable
                placeholder="请输入文件名称"
                size="small"
              ></el-input>
            </div>
            <div title="盖章日期" class="searchboxItem ci-full">
              <span class="itemLabel">盖章日期:</span>
              <el-date-picker
                clearable
                size="small"
                v-model="signDate"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </div>

            <div class="df" style="padding: 0 10px">
              <el-button type="primary" class="bgc-bv" round @click="getData()"
                >查询</el-button
              >
              <el-button
                type="primary"
                class="bgc-bv"
                round
                @click="multiPageFile()"
                >新增</el-button
              >
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
                fixed
              />
              <el-table-column
                label="上传文件名称"
                align="left"
                show-overflow-tooltip
                prop="fileName"
                min-width="200px"
              />
              <el-table-column
                label="操作人"
                align="left"
                show-overflow-tooltip
                prop="createUser"
                min-width="200"
              />

              <el-table-column
                label="操作时间"
                align="left"
                show-overflow-tooltip
                prop="createTime"
                min-width="150px"
              >
                <template slot-scope="scope">
                  {{ scope.row.createTime | moment }}
                </template>
              </el-table-column>
              <el-table-column
                label="操作"
                align="center"
                width="150px"
                fixed="right"
              >
                <div slot-scope="scope">
                  <el-button
                    type="text"
                    size="mini"
                    style="padding: 0 5px"
                    @click="download(scope.row.resultUrl)"
                    >下载</el-button
                  >
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
    <el-dialog
      title="文件盖章(循环章)"
      :visible.sync="multiPageLoading"
      top="1%"
      width="60%"
      @close="toVoidClose"
      class="toVoidLoding"
    >
      <el-form
        :model="ruleForm2"
        ref="ruleForm2"
        :rules="rules2"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="上传文件" prop="fileName">
          <el-upload
            class="upload-demo upload-btn"
            v-if="!ruleForm2.fileName"
            :action="actionUrl"
            :on-error="handleError"
            :on-success="handleSuccess"
            :on-change="uploadChange"
            :show-file-list="false"
            :auto-upload="false"
          >
            <el-button class="bgc-bv" size="mini">上传附件</el-button>
          </el-upload>
          <span v-else>
            {{ ruleForm2.fileName }}
            <a @click="reomveMuliPageExl" style="color: red; margin-left: 10px">删除</a>
          </span>
        </el-form-item>
        <el-form-item label="循环章规则" class="form-attribute" prop="signature">
          <el-input v-model="ruleForm2.signature"></el-input>
        </el-form-item>
      </el-form>
      <div class="bottom-btn">
        <el-button @click="multiPage('save')" class="bgc-bv"
          >保存</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import moment from "moment";
import { mapGetters } from "vuex";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "workOrder/circularseal",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      fileName: "", //姓名
      signDate: "", //日期
      multiPageLoading: false,
      ruleForm2: {
        fileName: "",
        fileKey: "",
        modelType: "4",   //循环章
        signature: "",
      },
      rules2: {
        fileName: [{ required: true, message: "请上传文件", trigger: "blur" }],
        signature: [{ required: true, message: "请填写CA签章规则", trigger: "blur" }],
      },
    };
  },
  watch: {},
  created() {},
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
      userJson: "getUser",
    }),
  },
  mounted() {
    this.getTableHeight();
  },
  methods: {
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.fileName) {
        params.fileName = this.fileName;
      }
      if (this.signDate) {
        params.signDateStart = this.signDate[0];
        params.signDateEnd = this.signDate[1];
      }

      this.doFetch({
        url: "/fileSign/batch/page",
        params,
        pageNum,
      });
    },
    /* 下载 */
    download(resultUrl) {
      window.open(resultUrl);
    },
    /* 上传文件 */
    uploadChange(file) {
      let size = file.size / 1024 / 1024;
      let extension = file.name.substring(file.name.lastIndexOf(".") + 1);
      const isXLSX = extension === "zip";
      if (!isXLSX) {
        this.$message.error("只能上传后缀是.zip的文件");
        return;
      }
      if (size > 50) {
        this.$message.error("文件大小不能超过50M");
        return;
      }
this.ruleForm2.fileName = file.name;
      let formData = new FormData();
      formData.append("folder ", "TEMP");
      formData.append("file ", file.raw);
      formData.append("fileType ", extension);
      this.$Postformat("/sys/upload", formData)
        .then((result) => {
          this.ruleForm2.fileKey = result.data.fileKey;
        //   this.ruleForm2.fileUrl = result.data.fileURL;
        })
        .catch(() => {
          setTimeout(() => {
            this.$message({
              type: "warning",
              message: "保存失败",
            });
          }, 300);
        });
      this.$forceUpdate();
    },
    /* 删除 */
    reomveExl() {
      this.ruleForm.fileName = "";
      this.ruleForm.fileKey = "";
      this.ruleForm.fileUrl = "";
    },
    /* 关闭时清除两个表单的内容 */
    toVoidClose() {
      this.ruleForm2.fileName = "";
      this.ruleForm2.fileKey = "";
      this.ruleForm2.modelType = "4";   //循环章
      this.ruleForm2.signature = "";
    },
    /* 循环章 */
    multiPageFile() {
      this.multiPageLoading = true;
    },
    /* 删除上传的文件 */
    reomveMuliPageExl() {
      this.ruleForm2.fileName = "";
      this.ruleForm2.fileKey = "";
      this.ruleForm2.fileUrl = "";
    },
    /* 盖循环章 */
    multiPage(stu) {
      this.$refs.ruleForm2.validate((valid, obj) => {
        if (valid) {
          let paramr = {
          fileKey: this.ruleForm2.fileKey,
          fileName: this.ruleForm2.fileName,
          signatureCode: this.ruleForm2.signature,
        };
        this.$post(
          "/fileSign/batch",
          paramr
        )
          .then((ret) => {
            if (ret.status == 0) {
                this.$message({
                  message: ret.message,
                  type: "success",
                });
                 let list = [];
              list.push(ret.data);
              for (let item of list) {
                if (!this.downloadItems.includes(item.taskId)) {
                  this.$store.dispatch("pushDownloadItems", item.taskId);
                } else {
                  this.$message.warning(
                    "[" + item.fileName + "]已经申请下载,请耐心等待"
                  );
                }
              }
                this.multiPageLoading = false;
                this.getData();
            }
          })
          .catch((err) => {
            return;
          });
        }
      });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 1.5) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 40;
      }
      this.tableHeight = tHeight;
      // // 40为thead高 | trHeight默认45为带按钮的tbody的tr高
      // this.pageSize = Math.ceil((tHeight -40) / trHeight);
    },
  },
  beforeRouteLeave: resetKeepAlive,
};
</script>
<style lang="less" scope>
.upload-btns {
  height: 40px;
  .el-upload {
    height: 40px !important;
    border: none !important;
  }
}
.upload-btn {
  width: 5rem;
  height: 28px;
  margin-top: 6px;
  .el-upload {
    width: 5rem;
    height: 28px !important;
    border: none !important;
  }
}
.el-radio-group {
  margin-left: 0.75rem;
}
.bottom-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.el-checkbox-group {
  display: flex;
  flex-direction: column;
}
</style>
